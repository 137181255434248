import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { WindowRef } from '@x/common/browser';
import { IShopChannelLivestream } from '@x/content/client';
import { ChannelState } from '@x/ecommerce-shop/app/channel/state';
import { combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

const DISABLED_PATHS = ['/checkout', '/login', '/my-account', '/register'];

@Component({
  selector: 'x-shop-livestream',
  templateUrl: './shop-livestream.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'x-shop-livestream' },
  imports: [AsyncPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShopLivestreamComponent {
  private livestream$ = this.store.select(ChannelState.activeChannelContentLivestream);

  private isRouteDisabled$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event) => DISABLED_PATHS.some((path) => event.urlAfterRedirects.includes(path))),
    startWith(DISABLED_PATHS.some((path) => this.router.url.includes(path))),
  );

  enabledPlayer$: Observable<IShopChannelLivestream | null> = combineLatest([
    this.livestream$,
    this.isRouteDisabled$,
  ]).pipe(
    map(([livestream, isDisabled]) =>
      livestream?.floatingPlayer && !isDisabled ? livestream : null,
    ),
    distinctUntilChanged(),
    tap((player) => {
      if (!player) this.closePlayer();
    }),
  );

  constructor(
    private store: Store,
    private router: Router,
    private windowRef: WindowRef,
  ) {}

  private closePlayer(): void {
    this.windowRef.get('_fwn')?.player?.close();
  }
}
