import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShopNotificationComponent } from './shop-notification.component';

@NgModule({
  declarations: [ShopNotificationComponent],
  exports: [ShopNotificationComponent],
  imports: [CommonModule],
})
export class ShopNotificationModule {}
