import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IShopChannelNotificationContent } from '@x/content/client';
import { ShopStorage } from '@x/ecommerce-shop/app/core/shop-storage/shop-storage.service';

@Component({
  selector: 'x-shop-notification',
  templateUrl: './shop-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'x-shop-notification' },
})
export class ShopNotificationComponent {
  showNotification: boolean = this.shopStorage.channelNotification?.visible ?? true;

  _notification: IShopChannelNotificationContent | null = null;
  @Input() set notification(notification: IShopChannelNotificationContent | null | undefined) {
    if (!notification?.notificationText) return;

    const storageMessage = this.shopStorage.channelNotification?.message;
    const { notificationText, background } = notification;

    if (notificationText !== storageMessage) {
      this.shopStorage.channelNotification = { visible: true, message: notificationText };
      this.showNotification = true;
    }

    this._notification = notification;
  }

  get notification() {
    return this._notification;
  }

  constructor(private shopStorage: ShopStorage) {}

  exitNotification() {
    this.showNotification = false;
    this.shopStorage.channelNotification = {
      visible: false,
      message: this.shopStorage.channelNotification?.message ?? '',
    };
  }
}
