import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { IShopChannelNotificationContent } from '@x/content/client';
import { ChannelState } from '@x/ecommerce-shop/app/channel/state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'x-shop-header',
  templateUrl: './shop-header.component.html',
  styleUrls: ['./shop-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-shop-header',
  },
})
export class ShopHeaderComponent {
  @HostBinding('class')
  @Input()
  headerStyle: 'primary' | 'accent' | 'warn' | 'default' | 'custom' | 'custom-mobile' = 'default';

  notification$: Observable<IShopChannelNotificationContent | null | undefined> = this.store
    .select(ChannelState.activeChannelContent)
    .pipe(map((response) => response?.channelNotification));

  constructor(private store: Store) {}
}
