import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrandingModule } from '@x/ecommerce-shop/app/core/shop-ui/components/branding/branding.module';
import { HeaderLayoutModule } from '@x/ecommerce-shop/app/core/shop-ui/components/layout/header-layout/header-layout.module';
import { ShopHeaderComponent } from '@x/ecommerce-shop/app/core/shop-ui/components/shop-header/shop-header.component';
import { ShopLivestreamComponent } from '@x/ecommerce-shop/app/core/shop-ui/components/shop-livestream/shop-livestream.component';
import { ShopNotificationModule } from '@x/ecommerce-shop/app/core/shop-ui/components/shop-notification/shop-notification.module';

@NgModule({
  declarations: [ShopHeaderComponent],
  imports: [
    RouterModule,
    BrandingModule,
    HeaderLayoutModule,
    CommonModule,
    ShopNotificationModule,
    ShopLivestreamComponent,
  ],
  exports: [ShopHeaderComponent],
})
export class ShopHeaderModule {}
